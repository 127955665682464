import React, { useEffect, useRef } from 'react';
import './google.css';
import anime from 'animejs';

export default function LogIn(props) {

    const gridRef = useRef();

    function authenticationNavigate(){
        const anchor = document.createElement('a');
        anchor.setAttribute('href', 'http://localhost:500/auth/google');
        console.log(anchor);
        anchor.click();
        
    }

    useEffect(()=>{
        applyAnimation();
    })

    function applyAnimation(){
        anime({
            targets: '.slidingImages .element',
            scale: [
              {value: .1, easing: 'easeOutSine', duration: 500},
              {value: 1, easing: 'easeInOutQuad', duration: 1200}
            ],
            delay: anime.stagger(300, {grid: [14, 5], from: 'center'})
        });
      }

    return (
        <div className="grid grid-cols-1 md:grid-cols-4 h-screen">
            <div className="w-full max-w-md p-2 shadow-lg">
                <div className='bg-white p-2 flex justify-center'>
                    <img src="/images/logo/Logo1.jpg" alt="" className='rounded-md m-2 md:h-24 h-16'/>
                </div>
                <h1 className='mt-8 font-bold'>Presents</h1>
                <h2 className="text-3xl font-bold mb-8 francois-one-regular text-blue-900">CTOP INDIA MISSION</h2>
                <h2 className="text-2xl font-bold my-4">LogIn to your account</h2>
                <h2 className="text-base font-bold my-4">Don't have an account?<span className=' text-blue-700'>Sign Up</span></h2>
                    <div className="mb-4">
                        <button className="gsi-material-button shadow-md" onClick={authenticationNavigate}>
                            <div className="gsi-material-button-state"></div>
                            <div className="flex justify-center items-center">
                                <div className="gsi-material-button-icon">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ display: 'block' }}>
                                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                        <path fill="none" d="M0 0h48v48H0z"></path>
                                    </svg>
                                </div>
                                <span className="gsi-material-button-contents text-md font-bold">Sign in with Google</span>
                            </div>
                        </button>
                    </div>
            </div>
            <div className='relative md:col-span-3 slidingImages grid grid-cols-4' ref={gridRef}>
                <div className='w-full h-full top-0 bg-white/70 absolute z-10'>
                </div>
                <div className="element flex justify-center items-center ">
                    <img src="images/nursing/nurse3.jpg" style={{ width: '100%', height: '100%',objectFit:'fill'}} alt="" />
                </div>
                <div className="element ">
                    <img src="images/onlineDr/drHomeService.jpg" style={{ width: '100%', height: '100%',objectFit:'fill'}} alt="" />
                </div>
                <div className="element">
                    <img src="images/Screening/Dr.jpg" style={{ width: '100%', height: '100%',objectFit:'fill'}} alt="" />
                </div>
                <div className="element flex justify-center items-center">
                    <img src="images/onlineDr/Nurse.jpg" style={{ width: '100%', height: '100%',objectFit:'fill'}} alt="" />
                </div>
                <div className="element flex justify-center items-center">
                    <img src="images/bihar/bihar(48).jpg" style={{ width: '100%', height: '100%',objectFit:'fill'}} alt="" />
                </div>
                <div className="element flex justify-center items-center">
                    <img src="images/drones/Drone7(2).jpg" style={{ width: '100%', height: '100%',objectFit:'fill'}} alt="" />
                </div>
                <div className="element">
                    <img src="images/Gallery/Event/event (4).jpg" style={{ width: '100%', height: '100%',objectFit:'fill'}} alt="" />
                </div>
                <div className="element">
                    <img src="images/Projects/Project2.jpg" style={{ width: '100%', height: '100%',objectFit:'fill'}} alt="" />
                </div>
                <div className='flex justify-center items-center  p-5 element'>
                    <img className='h-19 md:h-24 m-auto aspect-video' src="/images/PartnerSymbols/SGT.jpg" alt="SGT University" />
                </div>
                <div className='flex justify-center items-center  p-5 element'>
                    <img className='h-19 md:h-24 m-auto aspect-video' src="/images/PartnerSymbols/Kanchi.jpeg" alt="IITMadras" />
                </div>
                <div className='flex justify-center items-center  p-5 element'>
                    <img className='h-19 md:h-24 m-auto aspect-video' src="/images/PartnerSymbols/modulus.jpeg" alt="Modulus Parteners of CTOP" />
                </div>
                <div className=' justify-center items-center hidden md:flex p-5 element'>
                    <img className='hidden md:block w-48 md:w-48 h-19 md:h-21 m-auto aspect-video' src="/images/PartnerSymbols/ePlaneCo.jpeg" alt="ePlane Corporation" />
                </div>
                <div className='flex justify-center items-center  p-5 md:hidden element'>
                    <img className='block w-64 md:w-44 h-12 md:h-9 m-auto aspect-video' src="/images/PartnerSymbols/Visionflex.png" alt="Visionflex" />
                </div>
                <div className=' justify-center items-center hidden md:flex element'>
                    <img className='w-48 md:w-44 h-9 md:h-9 m-auto aspect-video' src="/images/PartnerSymbols/Visionflex.png" alt="Visionflex" />
                </div>
                <div className='flex justify-center items-center  p-5 element'>
                    <img className=' h-28 md:h-32 m-auto aspect-sqaure' src="/images/PartnerSymbols/Ayurvedic.png" alt="AIIMS" />
                </div>
                <div className='flex justify-center items-center  p-5 element'>
                    <img className='h-24 md:h-28 m-auto aspect-sqaure' src="/images/PartnerSymbols/IITMadras.png" alt="IITMadras" />
                </div>
                <div className='flex justify-center items-center md:hidden p-5 element'>
                    <img className=' block w-48 md:w-48 h-19 md:h-24 m-auto aspect-video' src="/images/PartnerSymbols/ePlaneCo.jpeg" alt="ePlane Corporation" />
                </div>
                <div className='flex justify-center items-center  p-5 element'>
                    <img className='h-24 md:h-28 m-auto aspect-sqaure' src="/images/PartnerSymbols/MIMS.png" alt="Mediciti Institute of Medical Science" />
                </div>
            </div>
        </div>
    )
}