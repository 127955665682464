import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Sidenavbar(props) {
  const [buttonState, setButtonState] = useState(false);
  const [ctopProgramButton, setCtopProgramButton] = useState(false);
  const [workCare, setWorkCare] = useState(false);
  const [healthCare, setHealthCare] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="bg-white h-full overflow-y-scroll hidden md:block w-auto hiddenScroll shadow-2xl z-10">
      <ul>
        <li
          onClick={() => setCtopProgramButton(!ctopProgramButton)}
          className={`px-5 py-5 cursor-pointer
                ${ctopProgramButton ? "bg-violet-400" : "bg-white"}
                hover:bg-violet-400  transition-all duration-500 ease-out  flex justify-between items-center border-violet-500 text-sm`}
        >
          CTOP Program
          <span className="material-symbols-outlined">
            {ctopProgramButton ? "expand_less" : "expand_more"}
          </span>
        </li>
        {ctopProgramButton ? (
          <ul className="bg-violet-200  rounded-sm p-5">
            <li
              onClick={() => navigate("/program/home")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 rounded-sm p-1 transition-all duration-500"
            >
              Home
            </li>
            <li
              onClick={() => navigate("/aboutus")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              About Us
            </li>
            <li
              onClick={() => navigate("/program/team")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Team
            </li>
            <li
              onClick={() => navigate("/program/care-services")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Care Services
            </li>
          </ul>
        ) : (
          ""
        )}
        <li
          onClick={() => setButtonState(!buttonState)}
          className={`px-5 py-5 cursor-pointer
                ${buttonState ? "bg-violet-400" : "bg-white"}
                hover:bg-violet-400  transition-all duration-500 ease-out  flex justify-between items-center border-t-2 border-violet-300 text-sm`}
        >
          CTOP Panchayat Hub
          <span className="material-symbols-outlined">
            {buttonState ? "expand_less" : "expand_more"}
          </span>
        </li>
        {buttonState ? (
          <ul className="bg-violet-200  rounded-sm p-5">
            <li
              onClick={() => navigate("/panchayat/healthBeing")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              HealthCare & welbeing
            </li>
            <li
              onClick={() => navigate("/narsing")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Nursing
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link")
              }
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Service Booking
            </li>
            <li
              onClick={() => navigate("/annualSubsription")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              CTOP Hub Membership
            </li>
            {/* <li onClick={()=> navigate("/panchayat/groupInsurance")} className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1' >
                                CTOP Panchayat Group Insurance
                            </li> */}
            {/* <li onClick={()=> navigate('/panchayat/Subscription')} className=' my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1' >
                             Panchayat Hub Insurance
                        </li> */}
          </ul>
        ) : (
          ""
        )}
        <li
          onClick={() => setHealthCare(!healthCare)}
          className={`px-5 py-5 cursor-pointer
                ${healthCare ? "bg-violet-400" : "bg-white"}
                hover:bg-violet-400  transition-all duration-500 ease-out  flex justify-between items-center border-t-2 border-violet-300 text-sm`}
        >
          CTOP Home Health Care
          <span className="material-symbols-outlined">
            {healthCare ? "expand_less" : "expand_more"}
          </span>
        </li>
        {healthCare ? (
          <ul className="bg-violet-200  rounded-sm p-5">
            <li
              onClick={() => navigate("/HomeHealthCare")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Home Health Care
            </li>
            <li
              onClick={() => navigate("/narsing")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Home Care Nursing
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link")
              }
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Home Care Booking
            </li>
            <li
              onClick={() => navigate("/HomeHealthCare/memberShip")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Home Care Membership
            </li>
            <li className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1">
              Home Care Consumable
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link")
              }
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Home Care Transport
            </li>
          </ul>
        ) : (
          ""
        )}
        <li
          onClick={() => setWorkCare(!workCare)}
          className={`px-5 py-5 cursor-pointer
                ${workCare ? "bg-violet-400" : "bg-white"}
                hover:bg-violet-400  transition-all duration-500 ease-out  flex justify-between items-center border-t-2 border-violet-300 text-sm`}
        >
          Work Healthcare
          <span className="material-symbols-outlined">
            {workCare ? "expand_less" : "expand_more"}
          </span>
        </li>
        {workCare ? (
          <ul className="bg-violet-200  rounded-sm p-5">
            <li
              onClick={() => navigate("/workCareMedicle")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Workcare Medical
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link")
              }
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Workcare Site Visit
            </li>
            <li
              onClick={() => navigate("/panchayat/preregister")}
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Care Service Booking
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLScj8thMXMRcgKJFEIT-nKkBUKKXH4lQkJckjrp_6UZ3GKkzmA/viewform?usp=sf_link")
              }
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Employee Family Care
            </li>
            <li
              onClick={() =>
                (window.location.href =
                  "https://docs.google.com/forms/d/e/1FAIpQLSdx57l0ZUZNctasszCmroAJAka8SsRGJCdik-LYKUpJsxaNVg/viewform?usp=sf_link")
              }
              className=" my-2 cursor-pointer  text-sm hover:bg-violet-300 transition-all duration-400 rounded-sm p-1"
            >
              Organisation Subscription
            </li>
          </ul>
        ) : (
          ""
        )}
        <li
          onClick={() => navigate("/narsing")}
          className="px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm"
        >
          Nursing Health
        </li>
        <li
          onClick={() => navigate("/telehealth")}
          className="px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm"
        >
          Online Telehelth
        </li>

        <li
          onClick={() => navigate("/University&School")}
          className="px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm"
        >
          School Univarsity Healthcare
        </li>
        <li
          onClick={() => navigate("/mentalHelth")}
          className="px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm"
        >
          Mental Health
        </li>

        {/* <li onClick={()=>navigate('/mentalHelth')} className='px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm'>
                    Women Health
                </li>
                
                <li onClick={()=>navigate('/mentalHelth')} className='px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm'>
                    Children Health
                </li>
                
                <li onClick={()=>navigate('/mentalHelth')} className='px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm'>
                    Disabilty Care
                </li>
                    
                    
                <li onClick={()=>navigate('/mentalHelth')} className='px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm'>
                    Ages Care
                </li>

                <li onClick={()=>navigate('/mentalHelth')} className='px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm'>
                    Patient Transport
                </li> */}

        <li
          onClick={() => navigate("/appointment")}
          className="px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm"
        >
          Appointment
        </li>

        <li
          onClick={() => navigate("/droneservices")}
          className="px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm"
        >
          Drone Services
        </li>
        <li
          onClick={() => navigate("/projects")}
          className="px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm"
        >
          CTOP Projects
        </li>
        <li
          onClick={() => navigate("/consumables")}
          className="px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm"
        >
          Consumables
        </li>
        <li
          onClick={() => navigate("/annualSubsription")}
          className="px-5 py-5 bg-white hover:bg-violet-400  transition-all duration-500 ease-out  border-t-2 border-violet-300 cursor-pointer text-sm"
        >
          Subscriptions
        </li>
      </ul>
    </div>
  );
}
